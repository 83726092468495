import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideMessaging, getMessaging } from '@angular/fire/messaging';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { AuthService } from './services/auth/auth.service';
import { ProfileSettingsComponent } from './tabs/profile/profile-settings/profile-settings.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProfileOrdersComponent } from './tabs/profile/profile-orders/profile-orders.component';
import { ProfileLanguageComponent } from './tabs/profile/profile-language/profile-language.component';
import { ProfileAddressComponent } from './components/profile-address/profile-address.component';
import { AddNewAddressComponent } from './components/add-new-address/add-new-address.component';
import { CountrySelectComponent } from './components/country-select/country-select.component';
import { AddressAutocompleteComponent } from './components/address-autocomplete/address-autocomplete.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { SelectDeliveryTimeComponent } from './components/select-delivery-time/select-delivery-time.component';
import { OrderDetailComponent } from './tabs/profile/profile-orders/order-detail/order-detail.component';
import { PaymentMethodsComponent } from './components/payment-methods/payment-methods.component';
import { ProductDetailPage } from './components/product-detail/product-detail.page';
import { SearchProductsPage } from './pages/search-products/search-products.page';
import { SharedModule } from './shared-module';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { AddressDetailPickerComponent } from './components/address-detail-picker/address-detail-picker.component';
import { NgxSlideConfirmModule } from '@koblers/ngx-slide-confirm';
import { GoogleMapsModule } from '@angular/google-maps';
import { CheckPostalcodeComponent } from './components/check-postalcode/check-postalcode.component';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { NgxGdprCookieConsentModule } from 'ngx-gdpr-cookie-consent';
import { ActivitiesComponent } from './tabs/profile/activities/activities.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
registerLocaleData(localeDe, 'de-DE', localeDeExtra);

@NgModule({
  declarations: [
    AppComponent,
    ProfileSettingsComponent,
    ProfileOrdersComponent,
    ProfileLanguageComponent,
    ProfileAddressComponent,
    AddNewAddressComponent,
    AddressAutocompleteComponent,
    OrderDetailComponent,
    CountrySelectComponent,
    SelectDeliveryTimeComponent,
    ProductDetailPage,
    PaymentMethodsComponent,
    SearchProductsPage,
    AddressDetailPickerComponent,
    ActivitiesComponent,
    CheckPostalcodeComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserAnimationsModule,
    NgxGdprCookieConsentModule.forRoot({

      cookieTypes: [
        {
          id: 'essential',
          name: 'Essenziell',
          description: 'Essenzielle Cookies ermöglichen grundlegende Funktionen und sind für die einwandfreie Funktion der Website erforderlich.',
          disabled: true,
          selected: true
        },
        {
          id: 'external',
          name: 'Externe Medien',
          scripts: [
            'https://maps.googleapis.com/maps/api/js?key=AIzaSyDju49z4v2RYLAUwk_L1iauwsELbvJqnC0&libraries=places',
            'https://www.googletagmanager.com/gtag/js?id=G-JB8EP52JQQ'
          ],
          description: 'Inhalte von Videoplattformen, Kartenmaterial und Social-Media-Plattformen werden standardmäßig blockiert. Wenn Cookies von externen Medien akzeptiert werden, bedarf der Zugriff auf diese Inhalte keiner manuellen Einwilligung mehr.'
        }
      ]
    }),
    GoogleMapsModule,
    BrowserModule,
    IonicModule.forRoot({
      mode: 'ios'
    }),
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
  
    NgxSlideConfirmModule,
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideMessaging(() => getMessaging()),
    provideStorage(() => getStorage()),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    SharedModule,

  ],
  providers: [
    AppVersion,


    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: FIREBASE_OPTIONS, useValue: environment.firebaseConfig },
    AuthService,
    InAppBrowser
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
