import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { LoadingController, ModalController, NavController, Platform } from '@ionic/angular';
import { Address } from 'src/app/types/address';

import firebase from 'firebase/compat/app';
import { AddressService } from 'src/app/services/address/address.service';
import { AddNewAddressComponent } from '../add-new-address/add-new-address.component';
import { Preferences } from '@capacitor/preferences';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-profile-address',
  templateUrl: './profile-address.component.html',
  styleUrls: ['./profile-address.component.scss'],
})
export class ProfileAddressComponent implements OnInit {

  addresses: Address[] = [];
  isLoading = true;

  constructor(private _auth: AngularFireAuth,
    private _firestore: AngularFirestore,
    private _loading: LoadingController,
    private _address: AddressService,
    private _nav: NavController,
    private _platform: Platform,
    private _http: HttpClient,
    private _modal: ModalController) { }


  ngOnInit() {
    this.loadAddresses();
  }

  dismiss() {
    this._modal.dismiss();
  }

  async loadAddresses(event = null) {
    try {
      this.isLoading = true;
      (await this._loading.create()).present();
      const sub = this._auth.authState.subscribe(user => {
        sub.unsubscribe();
        if (!user) {
          if (this._loading) {
            this.isLoading = false;
            this._loading.dismiss();
          }
          return
        }
        user.getIdToken().then(token => {
          this._http.get<any>(`${environment.api_url}/addresses`, {
            headers: {
              authorization: 'Bearer ' + token
            }
          }).subscribe(async result => {
            if (!result) {
              this.isLoading = false;
              if (event) {
                event.target.complete();
              }
              if (this._loading) {
                this._loading.dismiss();
              }
              this.addresses = [];
              return;

            }
            if (event) {
              event.target.complete();
            }
            if (this._loading) {
              this._loading.dismiss();
            }
            this.isLoading = false;
            this.addresses = await Promise.all(result.map(async (address) => {
              const result = (await this._firestore.collection('frischkisteArea')
                .doc(address.postCode)
                .get().toPromise());
              address.isSupported = result.exists;
              return address;
            }));
          }, error => {
            if (this._loading) {
              this.isLoading = false;
              this._loading.dismiss();
            }
            if (event) {
              event.target.complete();
            }
          })
        })

      })
    } catch (e) {
      if (this._loading) {
        this.isLoading = false;
        this._loading.dismiss();
      }
      if (event) {
        event.target.complete();
      }
    }
  }

  async add() {
    const modal = await this._modal.create({
      component: AddNewAddressComponent,
      cssClass: 'big-modal'
    });
    await modal.present();
    await modal.onWillDismiss();
    this.loadAddresses();

  }

  async deleteAddress(address: Address) {
    const userObj = await this._auth.currentUser;
    this.addresses.splice(this.addresses.findIndex(s => s == address), 1);
    await this._firestore.collection('users').doc(userObj.uid).update({
      addresses: this.addresses
    });


    if (address.country == this._address.currentAddress.country && address.housenumber == this._address.currentAddress.housenumber &&
      address.street == this._address.currentAddress.street && address.postCode == this._address.currentAddress.postCode) {
      this._address.currentAddress = null;
      await Preferences.remove({ key: 'location' });
    }

    this.loadAddresses();
  }

  selectAddress(address: Address) {
    this._address.selectAddress(address).then(() => {
      this._modal.dismiss(address);
    }).catch(() => { });
  }
}
