import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AddressService } from 'src/app/services/address/address.service';
import { Address, PlaceResult } from 'src/app/types/address';

@Component({
  selector: 'app-address-autocomplete',
  templateUrl: './address-autocomplete.component.html',
  styleUrls: ['./address-autocomplete.component.scss'],
})
export class AddressAutocompleteComponent implements OnInit {

  results: PlaceResult[] = [];
  searchString = '';

  constructor(private _modal: ModalController,
    private _address: AddressService) { }

  ngOnInit() {}

  dismiss() {
    this._modal.dismiss(null);
  }

  async updateSearch() {
    const results = await this._address.findResults(this.searchString);
    this.results = results;
  }

  async selectPlace(place: PlaceResult) {
    const result = await this._address.convertPlaceId(place.place_id);
    if (result) {
      this._modal.dismiss(result);
    }
  }
}
