import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as dayjs from 'dayjs';
import { CartService } from 'src/app/services/cart/cart.service';
import { environment } from 'src/environments/environment';

import { SwiperOptions } from 'swiper';

interface TimeSlot {
  date?: Date;
  time?: string;
  text: string;
  fullsize?: boolean;
}

@Component({
  selector: 'app-select-delivery-time',
  templateUrl: './select-delivery-time.component.html',
  styleUrls: ['./select-delivery-time.component.scss'],
})
export class SelectDeliveryTimeComponent implements OnInit {
  allowedDeliverys = 0;
  days: TimeSlot[] = [];
  timeSlots: TimeSlot[] = [];
  currentTime: string;
  currentDay: Date;
  dayjs = dayjs;
  slideOptions: SwiperOptions = {
    initialSlide: 0,
    freeMode: true,
    slidesPerView: "auto",
    spaceBetween: 10
  };

  alert = alert;

  constructor(private _modal: ModalController,
    private _http: HttpClient,
    private _cart: CartService,
    private _ref: ChangeDetectorRef) { }

  ngOnInit() {
    this.generateDays();

  }

  async dismiss(exit = false) {
    if (exit)
      this._modal.dismiss(null);
    const day = this.currentDay;
    const date = dayjs(this.currentDay).subtract(1, 'days')
    day.setHours(Number(this.currentTime.split(':')[0]), 0, 0);
    this._cart.setDeliveryDate({
      date: date.toISOString(),
      time: this.currentTime
    });
    this._modal.dismiss(this.currentTime);
  }

  generateDays() {
    this.days.push({
      date: this.setDefaultHours(dayjs().add(1, 'day').toDate()),
      text: 'Morgen',
      fullsize: true
    });
    for (var i = 1; i < 7; i++) {
      const date = dayjs().add(i + 1, 'day');
      this.days.push({
        date: this.setDefaultHours(date.toDate()),
        text: date.format('dd DD. MMM')
      });
    }
    
    this._http.get<any>(`${environment.api_url}/delivery/capacity?kind=Frischkiste`).subscribe(result => {
      this.days = result.capacities;
      this.allowedDeliverys = result.maximum;
      this.generateTimeSlots(result.capacities[0]);
      if (this._cart.deliveryDate) {
        this.currentDay = new Date(result.capacities[0].date)
      }
    })
    this._ref.detectChanges();
  }

  updateDate(slot, index: number) {
    const availability = this.days[index];
    this.currentDay = new Date(slot.date);
    this.currentTime = '';
    this.generateTimeSlots(availability);
  }

  generateTimeSlots(availability: any) {
    this.timeSlots = [];
    for (var i = 0; i < 5; i++) {
      if (!availability.orders) {
        return;
      } else {
        if (availability.orders.length == 0) {
          this.timeSlots.push({
            time: this.pad(10 + i * 2) + ':00',
            text: this.pad(10 + i * 2) + ':00 - ' + (12 + i * 2) + ':00'
          });
        } else {
          const string = this.pad(10 + i * 2) + ':00';
          const count = availability.orders.filter((object: any) => object._id === string);
          if (count.length == 0) {
            this.timeSlots.push({
              time: this.pad(10 + i * 2) + ':00',
              text: this.pad(10 + i * 2) + ':00 - ' + (12 + i * 2) + ':00'
            });
          } else {
            if (count[0].count < this.allowedDeliverys) {
              switch (count[0]._id) {
                case '10:00':
                  this.timeSlots.push({
                    time: '10:00',
                    text: '10:00 - 12:00'
                  });
                  break;
                case '12:00':
                  this.timeSlots.push({
                    time: '12:00',
                    text: '12:00 - 14:00'
                  });
                  break;
                case '14:00':
                  this.timeSlots.push({
                    time: '14:00',
                    text: '14:00 - 16:00'
                  });
                  break;
                case '16:00':
                  this.timeSlots.push({
                    time: '16:00',
                    text: '16:00 - 18:00'
                  });
                  break;
                case '18:00':
                  this.timeSlots.push({
                    time: '18:00',
                    text: '18:00 - 20:00'
                  });
                  break;
              }
            }
          }
        }
      }
    }
    this.currentTime = this._cart.deliveryDate?.time;
    this._ref.detectChanges();
  }

  pad(n) {
    return (n < 10) ? ("0" + n) : n;
  }
  isSameDate(date: Date) {
    return dayjs(date).isSame(this.currentDay, 'd');
  }
  setDefaultHours(date: Date) {
    date?.setHours(0, 0, 0);
    return date;
  }

}
