import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { AlertController, IonSlides, ModalController, Platform } from '@ionic/angular';
import { CartService } from 'src/app/services/cart/cart.service';
import { Product } from 'src/app/types/product';
import { environment } from 'src/environments/environment';
import { Share } from '@capacitor/share';

import { SwiperComponent } from 'swiper/angular';
import { TranslateService } from '@ngx-translate/core';

import StockUtil from 'src/app/util/stock';
import { DiscountService } from 'src/app/services/discount/discount.service';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.page.html',
  styleUrls: ['./product-detail.page.scss'],
})
export class ProductDetailPage implements OnInit {
  showBar = false;
  slideOptsOne = {
    initialSlide: 0,
    slidesPerView: 1,
    pagination: true
  };
  isAuthenticated = false;
  @Input()
  product: Product;
  stock = StockUtil;

  referral: Product[] = [];
  showNavigation = false;

  isInCart = false;
  animationTimeouts = [];

  itemsInCart = 0;

  @ViewChild(IonSlides, { static: false }) slider: SwiperComponent;

  constructor(private _cart: CartService,
    private _translate: TranslateService,
    private _platform: Platform,
    private _modalController: ModalController,
    private _ref: ChangeDetectorRef,
    private _alertController: AlertController,
    public _discount: DiscountService,
    private _http: HttpClient, private _afAuth: AngularFireAuth) { }

  ngOnInit() {
    setTimeout(() => {
      this.showBar = true;
    }, 200)
    this.determineIsLoggedIn();
    this.loadProduct();
  }

  determineIsLoggedIn() {
    this._afAuth.authState.subscribe(state => {
      this.isAuthenticated = state != null;
    });
  }

  async openModal(product: Product) {
    this.product = product;
    this.isInCart = false;
  }

  async loadProduct() {
    if (!this.product) {
      this._modalController.dismiss();
      return;
    }
    this.itemsInCart = await this._cart.getProductQTY(this.product);
    this.loadReferredProducts();
  }

  loadReferredProducts() {
    this.referral = [];
    this._http.get<Product[]>(`${environment.api_url}/products/referral/${this.product._id}&frischkiste=true`).subscribe(result => {
      this.referral = result;
    });
  }

  dismiss() {
    this._modalController.dismiss();
  }


  async share() {
    const shareBody = await this._translate.get('share_body').toPromise();
    const errorHeadline = await this._translate.get('no_gps_headline').toPromise();
    const errorMessage = await this._translate.get('share_exception').toPromise();
    const okBtn = await this._translate.get('ok').toPromise();
    try {
      await Share.share({
        title: 'Frischkiste',
        text: shareBody,
        url: `https://app.vomtuerken.de/productdetails/${this.product._id}`,
        dialogTitle: 'Frischkiste',
      });
    } catch (exception) {
      if (exception.message == 'Share API not available in this browser') {
        const alert = await this._alertController.create({
          cssClass: 'my-custom-class',
          header: errorHeadline,
          message: errorMessage,
          buttons: [{
            text: okBtn
          }
          ]
        });
        await alert.present();
      }
    }
  }
  async addToCart() {
    if (!StockUtil.check(this.product, this.itemsInCart) || !this._discount.checkMaximum(this.itemsInCart, this.product)) {
      return;
    }
    this.isInCart = false;
    setTimeout(() => this.isInCart = true);


    this._cart.addToCart(this.product, 1);
    if (this._platform.is('capacitor')) {
      Haptics.impact({
        style: ImpactStyle.Medium
      });
    }
    setTimeout(async () => {
      this.itemsInCart = await this._cart.getProductQTY(this.product);
    }, 200)
    this.animationTimeouts.forEach(id => {
      clearTimeout(id)
    });
    this.animationTimeouts = [];

    this.animationTimeouts.push(setTimeout(() => {
      if (this._platform.is('capacitor')) {
        Haptics.impact({
          style: ImpactStyle.Medium
        });
      }
    }, 1400));
    this.animationTimeouts.push(setTimeout(() => {
      if (this._platform.is('capacitor')) {
        Haptics.impact({
          style: ImpactStyle.Heavy
        });
      }
    }, 800));
  }

  isNew() {
    if (!this.product.createdAt) {
      return true;
    } else {
      const twoWeeksAgo = dayjs().subtract(14, 'days');
      console.log(twoWeeksAgo.toDate());

      if (twoWeeksAgo.toDate().getTime() < new Date(this.product.createdAt).getTime()) {
        return false
      } else {
        return true;
      }
    }
  }
}
