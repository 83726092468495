import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { Address, PlaceResult } from 'src/app/types/address';
import { Preferences } from '@capacitor/preferences';
import { LatLng } from '@capacitor/google-maps/dist/typings/definitions';
import { ProfileAddressComponent } from 'src/app/components/profile-address/profile-address.component';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { CheckPostalcodeComponent } from 'src/app/components/check-postalcode/check-postalcode.component';
import { NgxGdprCookieConsentService } from 'ngx-gdpr-cookie-consent';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  plz = '';
  currentAddress: Address;

  constructor(private _firestore: AngularFirestore, private _modal: ModalController,
    private afAuth: AngularFireAuth,
    private _platform: Platform,

    private _cookieService: NgxGdprCookieConsentService,
    private _alert: AlertController) {
  }

  public async loadLocationLocal() {

    try {
      const location = await Preferences.get({ key: 'location' });
      if (location && location.value) {
        this.selectAddress(JSON.parse(location.value))
      } else {
        const sub = this.afAuth.authState.subscribe(async user => {
          sub.unsubscribe();
          if (user) {
            if (this._platform.is('capacitor')) {
              const modal = await this._modal.create({
                component: ProfileAddressComponent
              });
              await modal.present();
            }
          } else {
            if (!this.plz) {
              const modal = await this._modal.create({
                component: CheckPostalcodeComponent
              });
              await modal.present();

            }
          }
        })
      }
    } catch (e) { }
  }

  public async selectAddress(address: Address, fromStorage = false) {
    if (address) {
      const deliveryArea = await this._firestore.collection('frischkisteArea').doc(address.postCode).get().toPromise();
      if (deliveryArea.exists) {
        this.currentAddress = address;

        if (!fromStorage) {
          Preferences.set({
            key: 'location',
            value: JSON.stringify(address)
          });
        }
        return true;
      } else {
        const alert = await this._alert.create({
          message: 'Wir liefern derzeit noch nicht in dieses Gebiet!',
          buttons: [
            {
              text: 'Okay'
            }
          ]
        });
        Preferences.remove({
          key: 'location'
        });
        alert.present();
        throw 'Not deliverying';
      }
    }
  }
  async clearCurrent() {
    this.currentAddress = null
    await Preferences.remove({
      key: 'location'
    });
  }
  public async findResults(search: string): Promise<PlaceResult[]> {
    if (!search)
      return [];
    let autocomplete = new google.maps.places.AutocompleteService();

    const result = await new Promise<PlaceResult[]>((resolve, reject) => {
      autocomplete.getPlacePredictions({
        input: search, types: [
          'address'
        ]
      }, (result) => {
        resolve(result.map(p => ({
          street: p.structured_formatting.main_text,
          city: p.structured_formatting.secondary_text,
          place_id: p.place_id
        }) as PlaceResult));
      }).catch(e => alert(e));
    });
    return result;
  }

  public async convertPlaceId(place_id: string) {
    const geocoder = new google.maps.Geocoder();

    const result = await geocoder.geocode({
      placeId: place_id
    });
    if (result.results.length >= 1) {
      return {
        geo: {
          lat: result.results[0].geometry.location.lat(),
          lng: result.results[0].geometry.location.lng()
        },
        address: {
          street: this.extractFromAdress(result.results[0].address_components, "route"),
          postCode: this.extractFromAdress(result.results[0].address_components, "postal_code"),
          town: this.extractFromAdress(result.results[0].address_components, "locality"),
          country: this.extractFromAdress(result.results[0].address_components, "country"),
          housenumber: this.extractFromAdress(result.results[0].address_components, "street_number")
        } as Address
      }
    }
    return result;
  }

  public async reverseGeoCode(latLng: LatLng): Promise<Address> {
    let geocoder = new google.maps.Geocoder();

    const result = await new Promise((resolve, reject) => {
      geocoder.geocode({ 'location': latLng }, (results, status) => {
        if (results.length >= 1) {
          return resolve({
            street: this.extractFromAdress(results[0].address_components, "route"),
            postCode: this.extractFromAdress(results[0].address_components, "postal_code"),
            town: this.extractFromAdress(results[0].address_components, "locality"),
            country: this.extractFromAdress(results[0].address_components, "country"),
            housenumber: this.extractFromAdress(results[0].address_components, "street_number")
          } as Address)
        }
        resolve(null);
      });
    });
    return result;
  }

  public extractFromAdress(components, type) {
    for (var i = 0; i < components.length; i++)
      for (var j = 0; j < components[i].types.length; j++)
        if (components[i].types[j] == type) return components[i].long_name;
    return "";
  }
}
