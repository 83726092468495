import { Location } from '@angular/common';
import { Component, Input, OnInit, } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LoadingController, ModalController } from '@ionic/angular';
import * as dayjs from 'dayjs';
import { Subscription } from 'rxjs';
import { CartService } from 'src/app/services/cart/cart.service';
import { CheckoutProduct, OrderStatus } from 'src/app/types/product';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss'],
})
export class OrderDetailComponent implements OnInit {
  trackingUrl = '';
  couponValue: number;
  subscription: boolean = false;
  subtotal = 0;
  @Input()
  orderId: string;
  products: CheckoutProduct[];
  status: OrderStatus;
  deliveredTimes = [
  ];
  total = 0;
  discount = 0;
  
  subscriber: Subscription;

  constructor(private _modal: ModalController,
    private location: Location,
    private _store: AngularFirestore,
    private _cart: CartService,
    private router: Router,
    private _loading: LoadingController,
    private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.loadOrder();

    this.subscriber = this.getRef().snapshotChanges().subscribe(result => {
      this.loadOrder();
    });
  }

  ngOnDestroy() {
    this.subscriber.unsubscribe();
  }

  dismiss() {
    this._modal.dismiss();
  }

  ngAfterViewInit() {
  }

  calculateTotal() {
    if (this.couponValue) {
      return (this.total - this.couponValue).toFixed(2).replace('.', ',')
    }
    if (this.subscription) {
      const rest = this.total - this.subtotal;
      return (this.subtotal * 0.9 + rest).toFixed(2).replace('.', ',')
    }
    return this.total.toFixed(2).replace('.', ',')
  }
  async loadOrder() {
    const order = await this.getRef().get().toPromise();
    this.subscription = order.get('subscription');
    this.deliveredTimes = order.get('oldDeliveries')
    this.products = order.get('products');
    this.status = order.get('status');
    this.trackingUrl = order.get('trackingUrl');
    this.total = order.get('total') ?? 0;
    this.discount = order.get('discount') ?? 0;
    this.couponValue = order.get('couponValue');
    this.subtotal = order.get('subtotal')
  }


  getRef() {
    return this._store.collection('orders').doc<any>(this.orderId);
  }

  back() {
    this.location.back();
  }

  getTrackingUrl() {
    if (this.trackingUrl && (this.status == 'order_confirmed' || this.status == 'order_delivered')) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(this.trackingUrl);
    } else {
      return null;
    }
  }
  async reorder() {
    const loading = await this._loading.create({
    });

    loading.present();
    this._cart.deleteCart();
    for (const product of this.products) {
      product.product._id = product.productId;

      await this._cart.addToCart(product.product, product.qty)
    };
    setTimeout(() => {
      loading.dismiss();
      this.router.navigate(['/checkout'])
      this._modal.dismiss();
    }, 100)


  }
}
